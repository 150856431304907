import React from 'react';

function Nivel(props) {
    var perc = props.percent.replace('%', '');
    //console.log(perc);
    return <section id="nivel">
        {perc > 99 &&
            <div className="alerta bg-alerta-maximo quadro">
                <div className="col-md-12">
                    <h3 onClick={(e) => props.Click}>{props.origin}</h3>
                    <h5>{props.location}</h5>
                    <br />
                    <br />
                    <h5>Última Atualização: {props.date} as {props.time}h</h5>
                    <h5>Chuva Acumulada Últimas 24h: {props.accumulated_rains_24h} mm</h5>
                    <br />
                    <h3>Porcentagem sobre a Cota: {props.percent}</h3>
                </div>
            </div>
        }        
        {(perc >= 80) && (perc < 99) && 
            <div onClick={(e) => props.Click} className="alerta bg-alerta quadro">
                <div className="col-md-12">
                    <h3>{props.origin}</h3>
                    <h5>{props.location}</h5>
                    <br />
                    <br />
                    <h5>Última Atualização: {props.date} as {props.time}h</h5>
                    <h5>Chuva Acumulada Últimas 24h: {props.accumulated_rains_24h} mm</h5>
                    <br />
                    <h3>Porcentagem sobre a Cota: {props.percent}</h3>
                </div>
            </div>
        }        
        {(perc >= 65) && (perc < 80) && 
            <div onClick={(e) => props.Click} className="alerta bg-atencao quadro">
                <div className="col-md-12">
                    <h3>{props.origin}</h3>
                    <h5>{props.location}</h5>
                    <br />
                    <br />
                    <h5>Última Atualização: {props.date} as {props.time}h</h5>
                    <h5>Chuva Acumulada Últimas 24h: {props.accumulated_rains_24h} mm</h5>
                    <br />                  
                    <h3>Porcentagem sobre a Cota: {props.percent}</h3>
                </div>
            </div>
        }        
        {perc < 65 &&
            <div onClick={(e) => props.Click} className="alerta bg-observacao quadro">
                <div className="col-md-12">
                    <h3>{props.origin}</h3>
                    <h5>{props.location}</h5>
                    <h5>Última Atualização: {props.date} as {props.time}h</h5>
                    <h5>Chuva Acumulada Últimas 24h: {props.accumulated_rains_24h} mm</h5>
                    <br />
                    <h3>Porcentagem sobre a Cota: {props.percent}</h3>
                </div>
            </div>
        }
    </section>;
}

export default Nivel;