import React from 'react';

function Banner(){
    return <section id="inicio">
        <div className="container">
            <div className="row">

                <div className="col-lg-10">
                    <h1>Defesa Civil de Santo Antônio de Pádua:</h1>
                    <h4>Trabalhando sempre na fiscalização e prevenção de eventos.</h4>
                    <a href="#alerta" className="btn btn-danger btn-lg btn-inicio"> <i className="fas fa-cloud-showers-heavy"></i> Alerta de Cheia</a>
                    <a href="#previsao" className="btn btn-outline-warning btn-lg btn-inicio"> <i className="fas fa-cloud-sun-rain"></i> Previsão do Tempo</a>
                    <a href="#contato" className="btn btn-outline-light btn-lg btn-inicio"> <i className="fab fa-whatsapp"></i> Contato</a>
                </div>

                <div className="col-lg-2">
                    <img src="images/template-author.png" alt="Defesa Civil" />
                </div>
       
            </div>
        </div>
    </section>;
  }

export default Banner;