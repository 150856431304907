import React from 'react';

function Footer(){
  var ano = new Date().getFullYear(); 

    return <section id="contato">    
      <div>
            <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item"><a href="https://www.facebook.com/secdefcivilpadua"><i className="fa fa-facebook fa-2x"></i></a></li>
                <li className="list-inline-item"><a href="https://www.santoantoniodepadua.rj.gov.br/"><i className="fas fa-rss-square fa-2x"></i></a></li>
                <li className="list-inline-item"><a href="https://www.youtube.com/watch?v=IhDvZsjYbn8"><i className="fab fa-youtube fa-2x"></i></a></li>						
                <li className="list-inline-item"><a href="mailto:ascom@santoantoniodepadua.rj.gov.br" target="_blank"><i className="fa fa-envelope fa-2x"></i></a></li>
                <li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=5522981557565&text=Entre%20em%20contato%20com%20a%20Defesa%20Civil%20de%20Santo%20Ant%C3%B4nio%20de%20P%C3%A1dua-RJ" target="_blank"><i className="fab fa-whatsapp fa-2x"></i></a></li>
            </ul>
	    </div>
      <p>Departamento de Comunicação - Gestão 2021/2024</p>
      <p>Desenvolvido por Departamento de Tecnologia Santo Antônio de Pádua-RJ - {ano}</p>

    </section>;
  }

export default Footer;