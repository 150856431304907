import React from 'react';

function Legenda() {
    return <section id="legenda">
        <div className="row alerta quadro">
            <div className="col-md-12">
                <div className="p-3 mb-3 bg-observacao text-white">ESTADO DE OBSERVAÇÃO</div>
            </div>
            <div className="col-md-12">
                <div className="p-3 mb-3 bg-atencao text-white">ESTADO DE ATENÇÃO</div>
            </div>
            <div className="col-md-12">
                <div className="p-3 mb-3 text-white bg-alerta">ESTADO DE ALERTA</div>
            </div>
            <div className="col-md-12">
                <div className="p-3 mb-3 bg-alerta-maximo text-white">ESTADO DE ALERTA MÁXIMO</div>
            </div>            
        </div>
    </section>;
}

export default Legenda;