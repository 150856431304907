import React, { useEffect, useState } from 'react';
import Legenda from './alertas/legenda';
import Nivel from './alertas/nivel';
import { api } from '../services/api';

function Alerta() {
    const [atualizado, setAtualizado] = useState(false);

    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [origin, setOrigin] = useState('');
    const [location, setLocation] = useState('');
    const [accumulated_rains_24h, setAccumuledRains24h] = useState('');
    const [percent, setPercent] = useState('');

    useEffect(() => {
        getClimate();
    }, []);


    async function getClimate() {
        api.get('/navigate')
            .then(response => {
                setDate(response.data.date);
                setTime(response.data.time);
                setOrigin(response.data.origin);
                setLocation(response.data.location);
                setAccumuledRains24h(response.data.accumulated_rains_24h);
                setPercent(response.data.percent);
                setAtualizado(true);
            })
            .catch(error => {
                console.log(error);
            })

        // try {
        //     const response = await api.get('/navigate');
        //     setDate(response.data.date);
        //     setTime(response.data.time);
        //     setOrigin(response.data.origin);
        //     setLocation(response.data.location);
        //     setAccumuledRains24h(response.data.accumulated_rains_24h);
        //     setPercent(response.data.percent);
        //     setAtualizado(true);
        // } catch (error) {
        //     console.error(error);
        // }
    }

    return <section id="alerta">
        <div className="container">

            <div className="row text-center">
                <div className="titulo">
                    <h1>Alerta de Cheia</h1>
                    <p>Fique atento a este painel! As cores indicam o estado de alerta do muninípio.</p>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-4">
                    <Legenda />
                </div>

                <div className="col-lg-8">
                    {/* <button className='btn btn-primary' onClick={(e) => getClimate()}>Clicar</button> */}
                    {atualizado === false ? <div className='section' id='section1'><p><img src="images/loading-buffering.gif" alt="Defesa Civil" /> Conectando no servidor remoto para obter informações...</p></div> : null}
                    {atualizado === true ? <Nivel onClick={getClimate()} date={date} time={time} origin={origin} location={location} accumulated_rains_24h={accumulated_rains_24h} percent={percent} /> : null}
                </div>
            </div>
        </div>
    </section>;
}

export default Alerta;