import React/*, { Fragment, useState, useEffect }*/ from 'react';
/*import axios from 'axios';*/

function Previsao() {
    // const [location, setLocation] = useState(false);
    // const [weather, setWeather] = useState(false);


    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         getWeather(position.coords.latitude, position.coords.longitude);
    //         setLocation(true)
    //     })
    // }, []);


    // let getWeather = async (lat, long) => {
    //     let res = await axios.get("http://api.openweathermap.org/data/2.5/weather", {
    //         params: {
    //             lat: lat,
    //             lon: long,
    //             appid: process.env.REACT_APP_OPEN_WHEATHER_KEY,
    //             lang: 'pt',
    //             units: 'metric'
    //         }
    //     });
    //     setWeather(res.data);
    //     console.log(res.data);
    // };

    // if (location == false) {
    //     return (
    //         <Fragment>
    //             Você precisa habilitar a localização no browser o/
    //         </Fragment>
    //     )
    // } else if (weather == false) {
    //     return (
    //         <Fragment>
    //             Carregando o clima...
    //         </Fragment>
    //     )
    // } else {
    //     return (
    //         <Fragment>
    //             <h3>Clima nas suas Coordenadas ({weather['weather'][0]['description']})</h3>
    //             <hr />
    //             <ul>
    //                 <li>Cidade: {weather['name']}</li>
    //                 <li>Clima: {weather['weather'][0]['description']}</li>
    //                 <li>Temperatura atual: {weather['main']['temp']}°</li>
    //                 <li>Temperatura máxima: {weather['main']['temp_max']}°</li>
    //                 <li>Temperatura minima: {weather['main']['temp_min']}°</li>
    //                 <li>Pressão: {weather['main']['pressure']} hpa</li>
    //                 <li>Humidade: {weather['main']['humidity']}%</li>

    //             </ul>
    //         </Fragment>
    //     );
    // }

    return <section id="previsao">
        <div className="container">

            <div id="carouselExampleInterval" className="carousel carousel-dark  slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="5000">
                        <img src="https://www.tempo.com/wimages/fotoa519285473fe992471d72b686e5f1031.png" />
                    </div>

                    {/* <div className="carousel-item " data-bs-interval="5000">
                        <iframe id="load-selo" allowtransparency="true" frameborder="0" data-url="https://www.climaaovivo.com.br/widget/cidade/2257" src="https://www.climaaovivo.com.br/widget/cidade/2257/h/5A5A5A/5A5A5A/ffffff" width="500px" height="160px" scrolling="no" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
                    </div> */}

                    {/* <div className="carousel-item " data-bs-interval="5000">
                        <iframe allowtransparency="true" marginwidth="0" marginheight="0" hspace="0" vspace="0" frameborder="0" scrolling="no" src="https://www.cptec.inpe.br/widget/widget.php?p=4715&w=h&c=909090&f=ffffff" height="200px" width="215px"></iframe><noscript>Previs&atilde;o de <a href="http://www.cptec.inpe.br/cidades/tempo/4715">Santo Antônio de Pádua/RJ</a> oferecido por <a href="http://www.cptec.inpe.br">CPTEC/INPE</a></noscript>
                    </div> */}

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    </section>;
}

export default Previsao;