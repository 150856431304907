import axios from 'axios';

export const api = axios.create({
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Headers': 'Authorization',
    //     'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    //     'Content-Type': 'application/json;charset=UTF-8'
    // },
    baseURL: 'http://54.39.40.7:3100'
    // baseURL: 'http://localhost:3100'
});