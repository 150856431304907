import React from 'react';

function AoVivo(){
    return <section id="aovivo">
        <div className="container">
            <div className="row">

                <div className="col-lg-10">
                    <h1>Acompanhe nossas mídias sociais para conhecer mais nossos serviços no dia-a-dia da população Paduana.</h1>
                    <br />
                    <img src="images/template-image.png" alt="Defesa Civil" />
                </div>

                <div className="col-lg-2">
                    <img src="images/right-image-post.png" alt="Defesa Civil" />
                    <br />
                    <img src="images/left-image-post.png" alt="Defesa Civil" />
                </div>
       
            </div>
        </div>
    </section>;
  }

export default AoVivo;