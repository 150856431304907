import React from 'react';
import Menu from './Components/menu';
import Banner from './Components/banner';
import Alerta from './Components/alerta';
import Previsao from './Components/previsao';
import AoVivo from './Components/aovivo';
import Footer from './Components/footer';

function Site(){
    return <div>
      <Menu/>
      <Banner/>
      <Alerta/>
      <Previsao/>
      <AoVivo />
      <Footer/>
    </div>;
  }

export default Site;